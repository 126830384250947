import {
  FormControl,
  FormErrorMessage,
  HStack,
  Stack,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { Controller } from "react-hook-form";
import { Toast } from "../../../components/Toast";
import Label from "../../../components/Label";
import { getAcademicYearFormat } from "../../../services/getAcademicYearFormat";
import { borderRadius } from "../../../utils/styles";
import { borderColor } from "../../../utils/colors";
import { REQUIRED_FIELD } from "../../../utils/constants";

export default function FormEditTabInfo({
  isDisabled,
  errors,
  register,
  control,
  setValue,
  year,
  yearFormat,
  formatYear,
  setFormatYear,
}) {
  const [academicYearFormatData, setAcademicYearFormatData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await getAcademicYearFormat();
      setAcademicYearFormatData(response);
    };
    getData();
  }, []);

  useEffect(() => {
    console.log();
  }, []);

  const inputCodeStyle = {
    padding: "10px",
    width: "100%",
    border: "1px solid",
    borderColor: borderColor,
    borderRadius: borderRadius,
  };

  const handleLabelInAcademicYear = (year) => {
    const label = year.includes("/")
      ? "4 + 4 dígitos - xxxx/xxxx"
      : "4 dígitos - xxxx";
    return label;
  };

  return (
    <>
      <Stack spacing={5}>
        <FormControl isInvalid={!!errors.yearFormat}>
          <Label title="Formato" isRequired={true} />
          <Controller
            control={control}
            name="yearFormat"
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                value={Number(value)}
                onChange={(e) => {
                  setValue("year", "");
                  onChange(Number(e));
                }}
              >
                <Stack direction="column">
                  {academicYearFormatData.map((e) => (
                    <Radio
                      onChange={() => {
                        setFormatYear(e.label);
                        console.log(e);
                      }}
                      value={Number(e.value)}
                    >
                      {handleLabelInAcademicYear(e.code)}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            )}
          />
          <FormErrorMessage>
            {errors.yearFormat && <>{REQUIRED_FIELD}</>}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.year}>
          <Label title="Ano Lectivo" isRequired={true} />
          <HStack width="200px">
            <InputMask
              {...register("year")}
              style={inputCodeStyle}
              mask={formatYear}
              value={year}
            />
          </HStack>
          <FormErrorMessage>
            {errors.year && <>{REQUIRED_FIELD}</>}
          </FormErrorMessage>
        </FormControl>
      </Stack>
    </>
  );
}
