import { useMemo } from "react";
import Table from "../../../components/Table";
import { Button } from "@chakra-ui/react";

function TableGeneralData({ data }) {
  let columns = useMemo(
    () => [
      {
        Header: "Indicador",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "Quadros padronizados",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <Button variant="secondary" isDisabled="true">
              Descarregar
            </Button>
          );
        },
      },
    ],
    []
  );

  return (
    <>{data && <Table columns={columns} data={data} isPaginated={false} />}</>
  );
}

export default TableGeneralData;
