import {
  Box,
  Button,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Toast } from "../../components/Toast";
import FormTabInfo from "./Forms/FormTabInfo";
import FormTabSubject from "./Forms/FormTabSubject";
import api from "../../utils/api";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { courseSchema } from "./courseSchema";
import { ZodError } from "zod";
import { COURSE_URL } from "../../utils/endpoints";
import { generateCode } from "../../utils/generateCode";
import FormTabInfoResume from "./Forms/FormTabInfoResume";
import { formStyles } from "../../utils/styles";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

export default function FormCourse() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [selectedOptionsSubject, setSelectedOptionsSubject] = useState([]);
  const [needsInitialValue, setNeedsInitialValue] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [actualRow, setActualRow] = useState({});
  const [optionsSubject, setOptionsSubject] = useState([]);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { id } = useParams();
  const [navigationDisabled, setNavigationDisabled] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setError,
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    resolver: zodResolver(courseSchema),
  });

  const { addToast } = Toast();
  const name = watch("name");
  const educationLevel = watch("educationLevel");
  const subsystemEducation = watch("educationSubsystem");
  const generalArea = watch("generalArea");
  const specificArea = watch("specificArea");
  const detailedArea = watch("detailedArea");
  const domainPNFQ = watch("domainPNFQ");
  const technicalEducation = watch("technicalEducation");
  const cicleEducationRead = watch("cicleEducation");

  useEffect(() => {
    if (!isDisabled && id) {
      setNavigationDisabled(false);
    }
  }, [isDisabled, id]);

  const handleValidateAndChangeTab = () => {
    try {
      courseSchema.parse(getValues());
      clearErrors();
      setNavigationDisabled(false);
      setActiveTab(activeTab + 1);
    } catch (e) {
      clearErrors();
      if (e instanceof ZodError) {
        e.issues.forEach((errors) => {
          console.log(errors);
          setError(errors.path[0], {
            type: "manual",
            message: errors.message,
          });
        });
      }
      return e.issues.length;
    }
  };

  const onSubmit = async (data) => {
    if (dataTable.length <= 0) {
      addToast({
        title: "Obrigatório associar disciplinas",
        status: "error",
      });
      throw new ZodError("Lista de disciplinas vazia");
    }

    const code = generateCode(1, 400);

    try {
      const subjects = dataTable.map((e) => e.value);
      const res = await api.post(COURSE_URL, {
        code,
        name: data.name,
        className: data.className,
        educationalLevel: data.educationLevel?.value,
        educationalSubsystem: data.educationSubsystem?.value,
        educationalCycle: data.cicleEducation?.value,
        generalArea: data.generalArea?.value,
        specificArea: data.specificArea?.value,
        detailedArea: data.detailedArea?.value,
        courseTechnical: data.technicalEducation?.value,
        pnfqDomain: data.domainPNFQ?.value,
        subjects,
      });

      if (res.status === 200) {
        navigate("/cursos");
        addToast({
          title: "Curso cadastrado com sucesso",
          status: "success",
        });
      }
    } catch (e) {
      console.log(dataTable);
      let errors = e.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
  };

  const handleDesassociate = async () => {
    const item = actualRow.data[actualRow.row.index];
    const dataFiltered = [
      ...dataTable.filter((e) => {
        if (e.code !== item.code) {
          return { code: e.code, label: e.label, value: e.label };
        }
      }),
    ];

    setOptionsSubject((current) => [
      ...current,
      { code: item.code, label: item.label, value: item.value },
    ]);

    setDataTable([...dataFiltered]);
    setIsOpenAlert(false);
  };

  const handleTabChange = (index) => {
    if (!navigationDisabled) {
      setActiveTab(index);
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Heading mb={4}>{!isDisabled ? "Cadastrar Curso" : null}</Heading>

      {isDisabled ? (
        <>
          <Heading mb={4}>Resumo | {name}</Heading>
          <Box sx={formStyles} mb={5}>
            <FormTabInfoResume
              errors={errors}
              control={control}
              watch={watch}
              dataTable={dataTable}
            />
          </Box>
        </>
      ) : (
        <>
          <Tabs mt="6" index={activeTab} onChange={handleTabChange}>
            <TabList>
              <Tab>Informações do Curso</Tab>
              <Tab>Disciplinas</Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0} pt="20px">
                <Heading as="h2" fontSize="md" textTransform="uppercase" pb={2}>
                  Informações do Curso
                </Heading>

                <Box sx={formStyles} mb={5}>
                  <FormTabInfo
                    errors={errors}
                    register={register}
                    watch={watch}
                    control={control}
                    isDisabled={isDisabled}
                    setValue={setValue}
                    backEdit={needsInitialValue}
                  />
                </Box>
              </TabPanel>

              <TabPanel p={0} pt="20px">
                <Heading as="h2" fontSize="md" textTransform="uppercase" pb={2}>
                  Disciplinas
                </Heading>

                <FormTabSubject
                  selectedOptionsSubject={selectedOptionsSubject}
                  setSelectedOptionsSubject={setSelectedOptionsSubject}
                  dataTable={dataTable}
                  setDataTable={setDataTable}
                  handleDesassociate={handleDesassociate}
                  actualRow={actualRow}
                  setActualRow={setActualRow}
                  optionsSubject={optionsSubject}
                  setOptionsSubject={setOptionsSubject}
                  isOpenAlert={isOpenAlert}
                  setIsOpenAlert={setIsOpenAlert}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}

      <Flex justifyContent="space-between">
        {isDisabled ? (
          <>
            <Button type="submit" mr={3}>
              Confirmar
            </Button>
            <Button
              onClick={() => {
                setNeedsInitialValue(true);
                setIsDisabled(false);
                setActiveTab(0);
              }}
              variant="secondary"
            >
              Voltar a editar
            </Button>
          </>
        ) : activeTab === 0 ? (
          <>
            <Box>
              <Button onClick={() => navigate("/cursos")} variant="secondary">
                Cancelar
              </Button>
            </Box>
            <Box>
              <Button
                variant="secondary"
                rightIcon={<MdKeyboardArrowRight />}
                onClick={handleValidateAndChangeTab}
              >
                Disciplinas
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Button type="submit" mr={3}>
                Cadastrar Curso
              </Button>
              <Button variant="secondary" onClick={() => navigate("/cursos")}>
                Cancelar
              </Button>
            </Box>
            <Box>
              <Button
                variant="secondary"
                leftIcon={<MdKeyboardArrowLeft />}
                onClick={() => handleTabChange(activeTab - 1)}
              >
                Informações do Curso
              </Button>
            </Box>
          </>
        )}
      </Flex>
    </Box>
  );
}
