import { useMemo, useEffect, useState, useContext } from "react";
import { Button, Heading, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { getAcademicYear } from "../../../../services/getAcademicYear";
import Table from "../../../../components/Table";
import api from "../../../../utils/api";
import {
  GET_MY_SCHOOL,
  SCHOOL_CLOSE_ACADEMIC_YEAR_FOR_MY_SCHOOL,
  SCHOOL_CLOSE_ACADEMIC_YEAR_FOR_SCHOOL,
  SCHOOL_URL,
} from "../../../../utils/endpoints";
import ErrorToCloseAcademicYear from "./ErrorsAcademicYear";
import { AuthContext } from "../../../../provider/AuthProvider";
import { Toast } from "../../../../components/Toast";

export default function TableCloseAcademicYear({ setDataTable, dataTable }) {
  const [isLoaded, setIsLoded] = useState(false);
  const [showErroCloseAcademicYear, setShowErroCloseAcademicYear] =
    useState(false);
  const [dataError, setDataError] = useState([]);
  const { addToast } = Toast();
  const { id } = useParams();
  const { auth } = useContext(AuthContext);
  const user = auth;

  useEffect(() => {
    if (id) {
      let apiUrl =
        user.isSchool === true ? GET_MY_SCHOOL : `${SCHOOL_URL}/${id}`;

      api.get(apiUrl).then((response) => {
        const { academicYears } = response.data;
        setDataTable(academicYears);
        setIsLoded(true);
      });
    }
  }, []);

  async function closeAcedmicYear(idAcademicYear) {
    try {
      let apiUrl =
        user.isSchool === true
          ? SCHOOL_CLOSE_ACADEMIC_YEAR_FOR_MY_SCHOOL
          : SCHOOL_CLOSE_ACADEMIC_YEAR_FOR_SCHOOL;

      const response = await api.patch(apiUrl, {
        schoolId: id,
        academicYearId: idAcademicYear,
      });

      if (response.data && response.data.success === false) {
        setShowErroCloseAcademicYear(true);
        setDataError(response.data);
      } else {
        addToast({
          title: "Ano Lectivo encerrado com sucesso",
          status: "success",
        });

        setShowErroCloseAcademicYear(false);
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Ano Lectivo",
        accessor: "academicYear.year",
        disableSortBy: true,
      },
      {
        Header: "Estado",
        disableSortBy: true,
        Cell: (item) => {
          return item.row.original.academicYear.open ? (
            item.row.original.academicYear.closeable ? (
              <Text>A encerrar</Text>
            ) : (
              <Text>A decorrer</Text>
            )
          ) : (
            <Text>Encerrado</Text>
          );
        },
      },
      {
        Header: "Encerrar",
        disableSortBy: true,
        Cell: (item) => {
          return (
            <>
              {user.isSchool === true &&
              user.user.permissions.includes("CloseAcademicYearForMySchool") ? (
                <Button
                  onClick={() =>
                    closeAcedmicYear(item.data[item.row.id].academicYear.id)
                  }
                  isDisabled={
                    item.data[item.row.id].academicYear.closeable === false
                      ? true
                      : false
                  }
                >
                  Encerrar
                </Button>
              ) : (
                <>
                  {user.user.permissions.includes(
                    "CloseAcademicYearForSchools"
                  ) && (
                    <Button
                      onClick={() =>
                        closeAcedmicYear(item.data[item.row.id].academicYear.id)
                      }
                      isDisabled={
                        item.data[item.row.id].academicYear.closeable === false
                          ? true
                          : false
                      }
                    >
                      Encerrar
                    </Button>
                  )}
                </>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Heading>Anos Lectivos</Heading>

      {showErroCloseAcademicYear ? (
        <ErrorToCloseAcademicYear data={dataError} />
      ) : (
        <Table
          setIsLoaded={setIsLoded}
          getFunction={getAcademicYear}
          setData={setDataTable}
          columns={columns}
          data={dataTable}
          isLoaded={isLoaded}
        />
      )}
    </>
  );
}
