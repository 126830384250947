import { useMemo } from "react";
import Details from "./Details";
import Table from "../../../../components/Table";

export default function TableErrorCloseAcademicYear({ data }) {
  const getTableData = () => {
    const tableData = [];

    if (
      data &&
      data.coursesWithoutTeacherAssigned &&
      data.coursesWithoutTeacherAssigned.length > 0
    ) {
      const totalErrors = calcularTotalDeErros(
        data.coursesWithoutTeacherAssigned
      );
      const description = "Existem cursos sem docentes associados";

      tableData.push({
        description: description,
        data: data.coursesWithoutTeacherAssigned,
        totalErrors: totalErrors,
      });
    }

    if (data && data.invalidCourses && data.invalidCourses.length > 0) {
      const totalErrors = calcularTotalDeErros(data.invalidCourses);
      const description = "Existem cursos por validar";

      tableData.push({
        description: description,
        data: data.invalidCourses,
        totalErrors: totalErrors,
      });
    }

    if (
      data &&
      data.invalidStudents &&
      data.invalidStudents.result.length > 0
    ) {
      const totalErrors = calcularTotalDeErros(data.invalidStudents.result);
      const description = "Existem alunos por validar";

      tableData.push({
        description: description,
        data: data.invalidStudents.result,
        totalErrors: totalErrors,
      });
    }

    if (
      data &&
      data.invalidTeachers &&
      data.invalidTeachers.result.length > 0
    ) {
      const totalErrors = calcularTotalDeErros(data.invalidTeachers.result);
      const description = "Existem docentes por validar";

      tableData.push({
        description: description,
        data: data.invalidTeachers.result,
        totalErrors: totalErrors,
      });
    }

    if (data && data.noCourses === true) {
      const totalErrors = 1;
      const description = "Não existem cursos";

      tableData.push({
        description: description,
        data: false,
        totalErrors: totalErrors,
      });
    }

    if (data && data.noStudents === true) {
      const totalErrors = 1;
      const description = "Não existem alunos";

      tableData.push({
        description: description,
        data: false,
        totalErrors: totalErrors,
      });
    }

    if (
      data &&
      data.studentsWithoutGrade &&
      data.studentsWithoutGrade.result.length > 0
    ) {
      const totalErrors = calcularTotalDeErros(
        data.studentsWithoutGrade.result
      );
      const description = "Existem alunos sem média de conclusão de curso";

      tableData.push({
        description: description,
        data: data.studentsWithoutGrade.result,
        totalErrors: totalErrors,
      });
    }

    if (
      data &&
      data.studentsWithoutResult &&
      data.studentsWithoutResult.result.length > 0
    ) {
      const totalErrors = calcularTotalDeErros(
        data.studentsWithoutResult.result
      );
      const description = "Existem alunos sem resultado";

      tableData.push({
        description: description,
        data: data.studentsWithoutResult.result,
        totalErrors: totalErrors,
      });
    }

    return tableData;
  };

  const calcularTotalDeErros = (dataArray) => {
    return dataArray.length;
  };

  let columns = useMemo(
    () => [
      {
        Header: "Número de erros",
        accessor: "totalErrors",
        disableSortBy: true,
      },
      {
        Header: "Descrição",
        accessor: "description",
        disableSortBy: true,
      },

      {
        Header: "Detalhes",
        accessor: "edit",
        disableSortBy: true,
        Cell: (item) => {
          return (
            item.row.original.data && (
              <Details
                data={item.row.original.data}
                details={item.row.original.description}
              />
            )
          );
        },
      },
    ],
    []
  );

  return <>{data && <Table data={getTableData()} columns={columns} />}</>;
}
