import { Box, Flex, Heading, Stack } from "@chakra-ui/react";
import TableStudents from "./TableStudents";

function TabStudents() {
  const data = [
    {
      id: 4,
      name: "Alunos matriculados (geral)",
    },
    {
      id: 2,
      name: "Alunos matriculados por necessidades educativas especiais",
    },
    {
      id: 5,
      name: "Alunos matriculados pela 1ª vez na 10ª classe",
    },
    {
      id: 1,
      name: "Alunos matriculados por resultado final",
    },
    {
      id: 6,
      name: "Alunos repetentes",
    },
    {
      id: 3,
      name: "Alunos diplomados",
    },
  ];
  return (
    <Stack spacing={5}>
      {/* <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb="10px">
          Filtrar
        </Heading>
      </Box> */}

      <Box>
        <Flex justifyContent="space-between" alignItems="end" mb="10px">
          <Heading as="h2" size="md" textTransform="uppercase">
            Lista de indicadores
          </Heading>
        </Flex>
        <TableStudents data={data} />
      </Box>
    </Stack>
  );
}

export default TabStudents;
