import { useEffect, useMemo, useState } from "react";
import Table from "../../../components/Table";
import api from "../../../utils/api";
import { ACADEMIC_YEAR_URL } from "../../../utils/endpoints";
import { useParams } from "react-router-dom";

function TableSchools() {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      const response = await api.get(`${ACADEMIC_YEAR_URL}/${id}`);
      setData(response.data.academicYearForSchools);
      setIsLoaded(true);
    };
    getData();
  }, []);

  let columns = useMemo(
    () => [
      {
        Header: "Escola",
        accessor: "school.name",
        disableSortBy: true,
      },
      {
        Header: "Estado",
        accessor: "closed",
        disableSortBy: true,
        Cell: (item) => {
          return item.row.original.closed ? "Encerrado" : "Por encerrar";
        },
      },
    ],
    []
  );

  return (
    <>
      {data && (
        <Table
          data={data}
          columns={columns}
          isLoaded={isLoaded}
          isPaginated={false}
        />
      )}
    </>
  );
}

export default TableSchools;
